import React from 'react'

function NotFound() {
  return (
    <div className='w-full px-10 sm:px-0 mx-auto md:w-8/12 py-10'>
        <h6 className='text-center text-[18px] font-[900] mb-2'>NOT FOUND</h6>
        <h6 className='text-[13px] text-center mb-2'>Sorry we couldn't find a page what you were looking for try after sometime.</h6>  

    </div>
  )
}

export default NotFound