import React from 'react'

function ChairmanMessage() {
  return (
    <div className='w-screen overflow-x-hidden'>
        <div className='relative w-full px-10 sm:px-0 mx-auto md:w-8/12 py-7 my-0'>
         <h6 data-aos="fade-left" className='text-[50px] md:text-[70px] -ml-2 text-[#8ae324] font_thicker'>Chairman Message</h6> 
                  <h6 className='text-[14px] my-5 font_thick'>With over 32 years of experience gained over the years, Mr Prabhudas Patel, as Chairman of Diwani Group, is spearheading the wood-based industry and has been a guiding force not only to his next generation but also to many.</h6>
                  <div className='flex items-center justify-center'>
                  <img src="https://wudgres.s3.ap-south-1.amazonaws.com/Screenshot+2023-06-04+at+3.23.17+PM.png" className='w-full flex align-center h-[250px] my-10 object-contain md:w-[50%]' />
                  </div>
                  <h6 className='text-[14px] my-5 font_bold font-[800]'>I bow my head to Mother Earth, who has provided all her natural resources for the good of
                    all of us. But as her children, we are reckless and exploiting them in a dangerous way. I
                    know we cannot do away with these resources so easily, but we have to find a way to
                    optimize and use them efficiently to keep the waste to a bare minimum.</h6>
                                      <h6 className='text-[14px] my-5 font_thick'>At Diwani Group, we have been making products where we make the best from left-over
                    wood from so many Saw Mills which is usually used as firewood. All the products made
                    under WudGres are of international standards and made to last, so there is little or no
                    need to replace them over time, which helps in saving on wood, which is again its
                    replacement.
                    </h6>
                                      <h6 className='text-[14px] my-5 font_thick'>We know brands are not made overnight and a lot of hard work goes into bringing them to
                    the top of the table. Even more difficult is to hold onto that position, which is only possible
                    with changing with time, upgrading technology, and consistent innovation, and giving the
                    best products to our customers that they deserve for the hard-earned money spent on
                    buying them.</h6>
                                      <h6 className='text-[14px] my-5 font_thick'>It is our consistent effort to provide a complete interior solution under WudGres. Keeping
                    this in mind, we have been expanding our existing portfolios and also bringing in new
                    products to make things easier for our customers.</h6>
                    <h6 className='text-[14px] my-5 font_thick'>With the effort of my hardworking team, we hope to see a WudGres in every home.</h6>
                    {/* <img src="https://wudgres.com/wp-content/uploads/2023/01/Prabhudas-Patel.gif" className='w-60 mb-10' /> */}


          {/* images  */}

          </div>
      </div>
  )
}

export default ChairmanMessage