import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import Navbar from './screens/navbar';
import Footer from './screens/footer';
import Branch from './screens/branch';
import Splash from './screens/splash';
import About from './screens/about';
import Application from './screens/application';
import Contact from './screens/contact';
import Products from './screens/products';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './screens/home';
import NotFound from './screens/NotFound';
import { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Warrenty from './screens/warrenty';
import CareFinishing from './screens/care_and_finishing';
import Privacy from './screens/privacy';
import Productscategory from "./screens/productscategory";
import ProductList from "./screens/products_list";
import ProductDetail from "./screens/product_detail";
import Catelogue from "./screens/catelogue";
import Introduction from "./screens/Introduction";
import ChairmanMessage from "./screens/ChairmanMessage";
import Wudgres from "./screens/wudgres";


export const app_url = 'https://wudgres-7329a.web.app/'

function App() {

  useEffect(()=>{
    AOS.init();
  },[])

  

  const [loader,setloader] = useState(false)

  const router = createBrowserRouter([
    {
      path: "",
      element:
      <>
        <Navbar />
        <Home />
        <Footer />
      </>
    }, 
    {
      path: "/",
      element:
      <>
      <Navbar />
      <Home />
      <Footer />
    </>
    }, 
    {
      path: "/about_us",
      element:
      <>
      <Navbar />
      <About />
      <Footer />
      </> 
    }, 
    {
      path: "/about_us/introduction",
      element:
      <>
      <Navbar />
      <Introduction />
      <Footer />
      </> 
    }, 
    {
      path: "/about_us/chairman_message",
      element:
      <>
      <Navbar />
      <ChairmanMessage />
      <Footer />
      </> 
    }, 
    {
      path: "/about_us/why_wudgres",
      element:
      <>
      <Navbar />
      <Wudgres />
      <Footer />
      </> 
    }, 
    {
      path: "/contact_us",
      element: 
      <>
      <Navbar />
      <Contact />
      <Footer />
      </> 
    }, 
    {
      path: "/mobile_app",
      element: 
      <>
      <Navbar />
      <Application />
      <Footer />
      </> 
    }, 
    {
      path: "/our_branch",
      element: 
      <>
      <Navbar />
      <Branch />
      <Footer />
      </> 
    }, 
    {
      path: "/products",
      element: 
      <>
      <Navbar />
      <Products />
      <Footer />
      </> 
    },  
    {
      path: "/products/:type",
      element: 
      <>
      <Navbar />
      <Products />
      <Footer />
      </> 
    },  
    {
      path: "/products/:type/:type",
      element: 
      <>
      <Navbar />
      <Products />
      <Footer />
      </> 
    },  
    {
      path: "/products_category/:type",
      element: 
      <>
      <Navbar />
      <Productscategory />
      <Footer />
      </> 
    },  
    {
      path: "/products_category/:type/products",
      element: 
      <>
      <Navbar />
      <ProductList />
      <Footer />
      </> 
    },  
    {
      path: "/products_category/:type/products/detail",
      element: 
      <>
      <Navbar />
      <ProductDetail />
      <Footer />
      </> 
    },  
    {
      path: "/warranty",
      element: 
      <>
      <Navbar />
      <Warrenty />
      <Footer />
      </> 
    },  
    {
      path: "/care-finishing",
      element: 
      <>
      <Navbar />
      <CareFinishing />
      <Footer />
      </> 
    },  
    {
      path: "/privacy-policy",
      element: 
      <>
      <Navbar />
      <Privacy />
      <Footer />
      </> 
    }, 
    {
      path: "/catalogue",
      element: 
      <>
      <Navbar />
      <Catelogue />
      <Footer />
      </> 
    },   
    {
      path: "*",
      element: 
      <>
      <Navbar />
      <NotFound />
      <Footer />
      </> 
    },  
  ])

  useEffect(()=>{
    setloader(true)
    setTimeout(() => {
      setloader(false) 
    }, 3000);
  },[])


  return (
    <div>
        {loader ?  
        <Splash /> 
        :
        <RouterProvider router={router} />}
    </div>
  );
}

export default App;
