import React from 'react'
import Fitting from '../images/fitting.png'
function CareFinishing() {
  return (
    <div className='w-9/12 mx-auto my-20'>
        <h6 className='text-[15px] my-5 font-[500] font-bold'>Care and Finishing</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>Your WudGres door has been handcrafted to last for years to come and like any piece of fine furniture, it needs proper finishing and periodic maintenance to keep it performing at its best. For a long-lasting, beautiful WudGres door, please follow the instructions as outlined in the appropriate section below. Failure to follow WudGres care & finishing recommendations will void the door's warranty.</h6>

        <h6 className='text-[15px] my-5 font-[500] font-bold'>FINISHING OPTIONS</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>Because WudGres doors are crafted with only the finest materials, you can choose from a wide variety of stain or paint possibilities. Paint dealers have a broad selection of color chips on hand to demonstrate the look you can achieve when staining fine wood such asAcacia and BurmaTeak used in WudGres doors. If you choose paint instead of stain, you can apply either oil-base or latex resin-base paints over an oil-base primer. However, take care in following the finishing instructions provided here.Also, be sure to evaluate the conditions your WudGres door must endure, such as direct exposure to severe climates, before deciding on the specific finish to use. All WudGres doors must be finished shortly before or after installation. CAUTION : If during the finish process you are having issues and/ or identify problems with your door, stop finishing immediately and contact the company.</h6>

        <h6 className='text-[15px] my-5 font-[500] font-bold'>HANDLING</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>1. Do not walk on any door units.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>2. Avoid dragging doors across one another and keep doors clean.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>3. Store flat on a level surface in a dry,well ventilated building.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>4. Doors should be conditioned to average prevailing relative humidity of the locality before hanging.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>5. Doors should not be subjected to extreme or rapid changes in heat or humidity.Avoid sudden changes such as forced heat to dry out a building.</h6>
       
        <h6 className='text-[15px] my-5 font-[500] font-bold'>PREPARATION FOR FINISHING</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>1. Before applying the first coat of finish, remove all handling marks, raised grain and other undesirable blemishes by sanding all surfaces with 180 grit sandpaper.To avoid cross-grain scratches, sand with the grain. Note:small amounts ofgrease,oil, or pitch can be wiped clean with mineral spirits.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>2. Adjust or align components if necessary before finishing.Wood panels"float" and may be knocked into alignment with a wood block and hammer. Be careful not to damage door.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>3. After sanding, clean the door thoroughly with a cloth to remove all dust or foreign material. Do not use caustic or abrasive cleaners.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>4. Hang door before finishing, and then remove to finish properly. Doors must be properly sealed prior to installation of hardware and exposure to moisture or weather. Properly finish door immediately before prolonged storage.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>5. All surfaces of the door must be properly finished.The edges (top, bottom and sides) should be coated with each and every coat of finish that is applied to the exterior surface of the door. Doors must be dry before finishing.</h6>
        
        <h6 className='text-[15px] my-5 font-[500] font-bold'>PAINT FINISH</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>Either oil-base or latex resin-base exterior grade paints may be used with success on panel and solid white doors.Oil-base paints offer more resistance to the passage of water (liquid and vapour) than latex resin-base paints, but the latter have better durability and color retention. Doors should be sealed with a good quality oil-base primer followed by two top coats of either an oil-base or latex resin-base paint. Of course, both primer and top coat should be made by the same manufacturer and designed to be used as a combination.</h6>

        <h6 className='text-[15px] my-5 font-[500] font-bold'>MAINTENANCE</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>Your WudGres door's finish will require some simple, periodic maintenance to keep it looking great and provide proper protection against the elements. Regular finish maintenance is also a requirement of the warranty that's included with every WudGres door. Here are some signs to watch for.They indicate that it's time for some simple, easy-to-perform maintenance:</h6>

        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>1. Hairline cracks in the top coat of finish.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>2. Changes in the colour of the finish.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>3. Changes in the texture of the finish, such as flaking or scaling.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>4. Dullness or chalkiness in the finish.</h6>
        
        <h6 className='text-[15px] my-5 font-[500] font-bold'>FITTING AND HANGING</h6>
        <div className='block md:flex'>
        <div>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>1. When hanging door, allow adequate clearance for swelling of door or frame in damp weather. Allow approximately 3/ I 6" clearance for swelling when the door is installed in fully dry conditions.Jambs must be plumb.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>2. Do not cut doors down in height by more than 2"( 1 - I /2" maximum from bottom or 1" on doors with UltraBlock* technology, I /2" maximum from top).Care should be taken in cutting doors down in width to avoid exposing engineered components. Use a sharp fine-tooth saw for trimming ends of doors.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>3. Caution must be used to avoid impairing the strength of the door when fitting for locks.Allow at least 1" of wood back of mortise.</h6>
        </div>
        <img src={Fitting} alt="fitting" className='h-60' />
        </div>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>4. Use three hinges on doors up to 7' in height and four hinges on doors over 7'. Hinges must be set in a straight line to prevent distortion.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>5. Jambs and stops must be Set Square and plumb.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>6. For best performance and to meet the standard WudGres warranty, any exterior door should be installed under an overhang or with sufficient protection.Adequate overhang depends on typical weather conditions of the area where the door is installed, but at the minimum means an overhang projecting a distance from the structure equal to one half the distance between the bottom of the door and the bottom of the overhang.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>7. Do not use dark colored stains or paints.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>8. Immediately after cutting and fitting (before hanging), seal all cut surfaces and ends of door with an effective quality sealer. See exterior and interior finishing recommendation for complete instructions.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>9. Prior to exterior exposure,doors must be finished with complete finish system.</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 mb-5 font_thick'>10. To minimize heat loss and save energy, use weather stripping.</h6>



    </div>
  )
}

export default CareFinishing