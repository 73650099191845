import React from 'react'
import { useNavigate } from 'react-router-dom'

function Footer() {
  
  const navigator = useNavigate()

  function openExternalLink(v,type){
    const url = 'https://www.facebook.com/wudgres/'
    const url1 = 'https://www.instagram.com/wudgres/?hl=en'
    const url2 = 'https://www.youtube.com/@wudgres'
    const url3 = 'https://api.whatsapp.com/send?phone=919844610001&text=Hi%20I%20would%20like%20to%20connect%20with%20you'

    if(type === 'mail'){
        window.open(`mailto:${v}`);
    }


    if(v === 1){
        window.open(url, '_blank');
    }else if(v === 2){
        window.open(url1, '_blank');
    }else if(v === 3){
        window.open(url2, '_blank');
    }else if(v === 4){
        window.open(url3, '_blank');
    }
  }

  function downloadfile(v){
    let cateloug = 'https://wudgres.s3.ap-south-1.amazonaws.com/WudGres+Master++Catalogu+2023+(1).pdf'
    let pricelist = 'https://wudgres.s3.ap-south-1.amazonaws.com/WudGres+PL+2023+(1).pdf'

    if(v === 1){
      window.open(cateloug, '_blank');
    }else if(v === 2){
        window.open(pricelist, '_blank');
    }
  }

  return (
    <div className='box-border w-screen overflow-x-hidden  bg-[#fafafa] text-white'>
        {/* <div className='py-5 border-b  border-[#fafafa] flex grid place-items-center'>
            <div className='flex'>
            <RiFacebookFill onClick={()=>openExternalLink(1)} size={13} className='mx-2 text-black opacity-75 hover:opacity-100 cursor-pointer' />
            <RiInstagramFill onClick={()=>openExternalLink(2)} size={13} className='mx-2 text-black opacity-75 hover:opacity-100 cursor-pointer' />
            <RiYoutubeFill onClick={()=>openExternalLink(3)} size={13} className='mx-2 text-black opacity-75 hover:opacity-100 cursor-pointer' />
            </div>
        </div> */}

        <div className='w-full px-10  font_thick mx-auto text-black py-12 md:px-20'>
            <div className='w-full place-items-start md:w-10/12 grid grid-cols-1 md:grid-cols-5  mx-auto md:place-items-center md:items-start' >
            <div className='mt-5 '>
            <h6 className='text-[12px] font-[700] opacity-100 uppercase mb-4 font_bold'>MAIN BRANCH </h6>

                <h6 className='text-[12px] font-[400] opacity-85 mb-2 uppercase font_bold'>Unit 1</h6>
                <h6 className='text-[12px] text-black opacity-75'>
                    Sri Hanuman Saw Mill <br></br>
                    Chikkabanavara,Bengaluru. Karnataka,India.<br></br>
                    E-mail : <span onClick={()=>openExternalLink('wudgres@gmail.com','mail')} className=' cursor-pointer  hover:opacity-100 hover:hover:underline'>wudgres@gmail.com</span> 
                </h6>

                <h6 className='text-[12px]  font-[400] opacity-85 uppercase mb-2 mt-5 font_bold'>Unit 2</h6>
                <h6 className='text-[12px] text-black opacity-75'>
                    WoodLord Corp. <br></br>
                    Nelamangala,Bengaluru. Karnataka,India.<br></br>
                    E-mail : <span onClick={()=>openExternalLink('wg.woodlord@gmail.com','mail')} className='cursor-pointer  hover:opacity-100 hover:hover:underline '>wg.woodlord@gmail.com</span> 
                </h6>
            </div>

            <div className='mt-5 text-black'>
                <h6 className='text-[12px] font-[700] opacity-100 uppercase mb-4 font_bold'>MORE INFO </h6>

                <h6 className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100 hover:underline' onClick={()=>navigator('/about_us/introduction')}>About Us</h6>
                <h6 className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100 hover:underline' onClick={()=>navigator('/contact_us')}>Contact Us</h6>
                <h6 className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline' onClick={()=>navigator('/products')}>Products</h6>
                <h6 className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline' onClick={()=>navigator('/our_branch')}>Our Shops</h6>
                <h6 className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline' onClick={()=>navigator('/mobile_app')}>Mobile App</h6>
            </div>

            <div className='mt-5 text-black'>
                <h6 className='text-[12px] font-[700] opacity-100 uppercase mb-4 font_bold'>OUR SERVICES</h6>

                <h6 onClick={()=>navigator('/products',{state:'doors'})} className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline'>Doors</h6>
                <h6 onClick={()=>navigator('/products',{state:'wpc'})} className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline'>WPC</h6>
                <h6 onClick={()=>navigator('/products',{state:'shutters'})} className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline'>Window Shutters</h6>
                <h6 onClick={()=>navigator('/products',{state:'board'})} className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline'>Ply Wood</h6>
                <h6 onClick={()=>navigator('/products',{state:'engineered'})} className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline'>Block Board</h6>
            </div>

            <div className='mt-5 text-black'>
                <h6 className='text-[12px] font-[700] opacity-100 uppercase mb-4 font_bold'>MORE LINKS</h6>

                <h6 onClick={()=>navigator('/warranty')} className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline'>Warranty</h6>
                <h6 onClick={()=>navigator('/care-finishing')} className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline'>Care & Finishing</h6>
                <h6 onClick={()=>navigator('/contact_us')} className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline'>Have Query</h6>
                <h6 onClick={()=>navigator('/mobile_app')} className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline'>Mobile App</h6>
                <h6 onClick={()=>downloadfile(1)} className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline'>Master Catelouge</h6>
                <h6 onClick={()=>downloadfile(2)} className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline'>PriceList Catelouge</h6>
               
            </div>

            <div className='mt-5 text-black'>
                <h6 className='text-[12px] font-[700] opacity-100 uppercase mb-4 font_bold'>FOLLOW US</h6>

                <h6 onClick={()=>openExternalLink(1)} className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline'>Facebook</h6>
                <h6 onClick={()=>openExternalLink(2)} className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline'>Instagram</h6>
                <h6 onClick={()=>openExternalLink(3)} className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline'>YouTube</h6>
                <h6 onClick={()=>openExternalLink(4)} className='text-[12px] mb-2 opacity-75 cursor-pointer  hover:opacity-100  hover:underline'>Whatsapp</h6>
            </div>
            </div>
        </div>
        
        <div className='border-t-[0.1px] pt-2  border-gray-200'>
        <span className='flex text-black opacity-70 text-[8px]  justify-center pb-2 items-center w-full font-[700] md:text-[8px] text-center mx-auto'><h6 className='mr-2 border-b border-white'>@2023-24</h6> By Wudgres <h6 className='ml-2 border-l pl-2 mr-2 pr-2 border-r border-white'>All rights Reserved</h6><h6>Designed By Spinesoft.</h6></span>
        </div>
    </div>
  )
}

export default Footer