import React from 'react'
import Mobile1 from '../images/mobile1.jpg'
import { useNavigate } from 'react-router-dom';






function Application() {

  
  const navigator = useNavigate()

  function openExternalLink(v){
    const url = 'https://apps.apple.com/in/app/wudgres/id6449000418'
    const url1 = 'https://play.google.com/store/apps/details?id=com.wudgresreal'

    if(v === 1){
        window.open(url, '_blank');
    }else if(v === 2){
        window.open(url1, '_blank');
    }
  }

  return (
    <div className='w-screen overflow-x-hidden'>
      <div className='md:mt-0 grid place-items-center justify-items-center text-white  bg-cover h-[230px] w-full bg-mobile_app  bg-black md:h-[350px] md:bg-cover bg-no-repeat bg-center'>
       

       <div>
        <h6 className='text-[30px] text-center tracking-[10px] font_expand'>Wudgres App</h6>
        <h6 className='text-center tracking-wider text-gray-600 opacity-100 text-[14px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Application </h6>
        <h6 className='text-[14px] font_thick text-center opacity-70'>We Made Secure Wudgres App for you to check the doors that suits your home. </h6>
        </div>

      </div>
          <div className='my-16 w-11/12  relative md:w-8/12  mx-auto'>
          <h1 data-aos="fade-left" className='text-[40px] md:text-[50px] leading-tight md:text-[70px] absolute right-0  -top-18 md:-top-10 text-[#8ae324] font_thicker'> WUDGRES MOBILE APP</h1>

            <div className='block md:flex  items-center justify-center mt-20'>

              <div className='w-full pt-16 md:pt-24 md:-mt-12 md:w-[90%] h-[600px]'>  
              <img  data-aos="fade-up" className='w-[100%] h-[100%] object-contain' src={Mobile1} alt="_mobile_app" />
              </div> 
              <div data-aos="fade-left" className='ml-5'>
                <h3 className='text-[14px] font_thick'> First Time Ever  <span >the door purchasing app by Wudgres...</span></h3>
                <h3 className='text-[35px] font-[700] text-left font_bold'> Make your doors choice Easyer <span >then before with Wudgres</span></h3>
                <h6 className='text-[15px] mt-2 font-[300] font_thick'>With a Huge Portfolio and multiple options and sub-options its always difficult to check the prices and come up with the exact rate for a particular size. wudgres app makes it easier for everyone to find the exact price of the product in detail. Now it's available for both Android and ios</h6>
                <h6 className='text-[15px] mt-2 font-[300] font_thick'>The app which is powered by  <span className='font_bold'>wudgres</span>.</h6>

                <div className='mt-5 flex'>
              <h6 onClick={()=>openExternalLink(2)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] text-center'>Google Play</h6>
                <h6 onClick={()=>openExternalLink(1)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] ml-2 text-center'>App Store</h6>

              </div>
              </div>
              </div>
          </div>

         <div>
          </div>  



        



         
    </div>

  )
}

export default Application