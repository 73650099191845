import React from 'react'
import certification from '../images/certification.png'

function Wudgres() {
  return (
    <div className='w-screen overflow-x-hidden'>
        <div className='relative w-full px-10 sm:px-0 mx-auto md:w-8/12 py-7 my-0'>
         <h6 data-aos="fade-left" className='text-[50px] md:text-[70px] -ml-2 text-[#8ae324] font_thicker'> WudGres</h6> 

         <h6 className='text-[14px]  mb-5 font-[400] text-justify font_thick'>Making the best products available for so many years and providing Unparalleled
Craftmanship, the customers have been WudGres' Brand Ambassadors, which is
our strength. It is through their efforts that WudGres is where it is today and will
reach new height in the years to come.</h6>
          <h6 className='text-[20px]  mb-5 font-[800] text-justify font_bold'>The Things We Make</h6>
          
          {/* <ul className='list-disc ml-5'>
          <li className='text-[14px] font-[400] text-justify font_thick'>Decorative Doors</li>
          <li className='text-[14px] font-[400] text-justify font_thick'>Flush Doors</li>
          <li className='text-[14px] font-[400] text-justify font_thick'>WPC</li>
          <li className='text-[14px] font-[400] text-justify font_thick'>Window Shutters</li>
          <li className='text-[14px] font-[400] text-justify font_thick'>PlyWood</li>
          <li className='text-[14px] font-[400] text-justify font_thick'>Block Board</li>
          <li className='text-[14px] font-[400] text-justify font_thick'>Eng. Wood Frames</li>
          <li className='text-[14px] font-[400] text-justify font_thick'>Laminates</li>
          <li className='text-[14px] font-[400] text-justify font_thick'>Veneers</li>
          </ul> */}


          <div className='grid grid-cols-2 md:grid-cols-5 gap-2'>
            <h6 className='text-[14px] font-[400] text-center uppercase rounded font_bold text-[#fff] bg-black p-6'>Decorative Doors</h6>
            <h6 className='text-[14px] font-[400] text-center uppercase rounded font_bold text-[#fff] bg-black p-6'>Flush Doors</h6>
            <h6 className='text-[14px] font-[400] text-center uppercase rounded font_bold text-[#fff] bg-black p-6'>WPC</h6>
            <h6 className='text-[14px] font-[400] text-center uppercase rounded font_bold text-[#fff] bg-black p-6'>Window Shutters</h6>
            <h6 className='text-[14px] font-[400] text-center uppercase rounded font_bold text-[#fff] bg-black p-6'>PlyWood</h6>
            <h6 className='text-[14px] font-[400] text-center uppercase rounded font_bold text-[#fff] bg-black p-6'>Block Board</h6>
            <h6 className='text-[14px] font-[400] text-center uppercase rounded font_bold text-[#fff] bg-black p-6'>Eng. Wood Frames</h6>
            <h6 className='text-[14px] font-[400] text-center uppercase rounded font_bold text-[#fff] bg-black p-6'>Laminates</h6>
            <h6 className='text-[14px] font-[400] text-center uppercase rounded font_bold text-[#fff] bg-black p-6'>Veneers</h6>
          </div>

          <h6 className='text-[20px]  mb-5 mt-10 font-[800] text-justify font_bold'>Why Wudgres</h6> 


          <ul className='list-disc ml-5 mb-10'>
            <li className='text-[14px] font-[400] text-justify font_thick'>Loads of Happy Customers.</li>
            <li className='text-[14px] font-[400] text-justify font_thick'>Design and size customization, even for a single door.</li>
            <li className='text-[14px] font-[400] text-justify font_thick'>A large network and a commitment to meeting delivery deadlines.</li>
            <li className='text-[14px] font-[400] text-justify font_thick'>With over 350 different designs and over 2000 different sub-options.</li>
            <li className='text-[14px] font-[400] text-justify font_thick'>Customers get to choose from wide variety of Veneers and Laminates
            which are also used in the doors.</li>
            <li className='text-[14px] font-[400] text-justify font_thick'>Made using the latest technology.</li>
            <li className='text-[14px] font-[400] text-justify font_thick'>A diverse portfolio under one brand, providing complete interior solutions.</li>
            <li className='text-[14px] font-[400] text-justify font_thick'>Doors for Residential, Commercial and Industrial application.</li>
            <li className='text-[14px] font-[400] text-justify font_thick'>A diverse portfolio under one brand.</li>
          </ul>



          <img src={certification} className='md:w-[50%]' />



          <h6 className='text-[20px]  mb-5 mt-10 font-[800] text-justify font_bold'>Certification</h6> 
          
          <div className='md:flex mt-10'>
          <div className='md:mr-5'>
            <h6 className='text-[14px]  font-[800] text-center font_bold'>ISO 9001 : 2015</h6>
            <h6 className='text-[12px] font-[400] text-center font_thick'>REG NO : CQCPL/QMS/0122/2290</h6>
          </div>

          <div className='mt-5 md:mt-0'>
            <h6 className='text-[14px]  font-[800] text-center font_bold'>ISO 14001 : 2015</h6>
            <h6 className='text-[12px] font-[400] text-center font_thick'>REG NO : CQCPL/EMS/0122/1970</h6>
          </div >
          </div>

          </div>


          {/* images  */}

      </div>
  )
}

export default Wudgres