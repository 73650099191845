import React from 'react'

// catelogue  
import woodlord_board from '../images/catelogue/woodlord_board.png';
import woodlord_door from '../images/catelogue/woodlord_door.png';
import woodlord_frames from '../images/catelogue/woodlord_frames.png';
import woodlord_pricelist from '../images/catelogue/woodgrace_pricelist.png';
import woodlord_mastercatelogue from '../images/catelogue/wudgres_mastercatelogue.png';
import woodlord_customercatelogue from '../images/catelogue/wugres_customercatelogue.png';

import {VscOpenPreview} from 'react-icons/vsc';

function Catelogue() {
  
  const data =  [
   //   {
   //      id:'1', 
   //      img:woodlord_board,
   //      value:'WOODLORD BOARD',
   //      file:'https://fidecrmfiles.s3.amazonaws.com/WOODLORD-BOARD.pdf'
   //   },
      {
         id:'1', 
         img:woodlord_mastercatelogue,
         value:'WOODLORD MASTER CATALOGUE 2023-24',
         file:'https://wudgres.s3.ap-south-1.amazonaws.com/WudGres+Master++Catalogu+2023+(1).pdf'
      },
      {
         id:'2', 
         img:woodlord_customercatelogue,
         value:'WUDGRES CUSTOMER CATALOGUE',
         file:'https://fidecrmfiles.s3.amazonaws.com/Customer+Catalog+2023+.pdf'
      },    
      {
         id:'3', 
         img:woodlord_pricelist,
         value:'WOODLORD PRICE LIST',
         file:'https://wudgres.s3.ap-south-1.amazonaws.com/WudGres+PL+2023+(1).pdf'
      },
      {
         id:'4', 
         img:woodlord_door,
         value:'WOODLORD WPC DOOR',
         file:'https://fidecrmfiles.s3.amazonaws.com/WOODLORD-DOOR.pdf'
      },
      {
         id:'5', 
         img:woodlord_frames,
         value:'WOODLORD WPC FRAMES',
         file:'https://fidecrmfiles.s3.amazonaws.com/WOODLORD-FRAMES.pdf'
      },
     
   ]  
  return (
    <div className='w-screen overflow-x-hidden min-h-screen py-12'>

      <div className='w-[85vw] md:w-[75vw] mx-auto'>
      <h1 data-aos="fade-left" className='text-[50px] md:text-[70px] -ml-2 text-[#8ae324] font_thicker'>CATALOGUES</h1>
      <h6 className='text-[14px] mb-5 font-[400] -mt-4 font_thick'>Get Detail Info about Wudgres Products Services and Pricing Using THe Catelogue's just designed for you</h6>


      <div className='grid md:grid-cols-3 gap-5 mt-10'>
        {data?.map((d,i)=>(
            <a href={d?.file} target='_blank' >
            <div key={i} className='group relative p-2 shadow cursor-pointer'>
               <VscOpenPreview size={25} className='transition-all duration-300 opacity-0 absolute group-hover:opacity-100 right-2 bg-white p-[5px] ' />
                <img src={d?.img} className={` h-[220px] ${i == 1 ?  'w-[98%] object-stretch' : 'w-[100%] object-stretch'}`} />
                <h6 className='font_bold'>{d?.value}</h6>
            </div> 
            </a>  
        ))}
        </div>
        </div>
    </div>
  )
}

export default Catelogue