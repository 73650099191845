import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

function Productscategory() {
  const navigator = useNavigate()
  const {state} = useLocation()
  // console.log("data",state)

  console.log("state?.data?.name",state?.data?.name)
    
  return (
    <div className='relative mx-10 md:mx-[70px] py-28'>

        {state?.data?.banner?.map((b,i)=>( 
        <div className={`${state?.data?.name === 'flush_doors' ? 'w-[95%] mx-auto' : 'w-[100%]'} md:flex items-center mb-10 hover:cursor-pointer ${(i == 1 && state?.data?.name === 'lamina') ? '-ml-6'  : (i == 1 && state?.data?.name === 'solid white') ? 'ml-1.5' : ''}`}>
          <img onClick={()=>navigator(`/products_category/${state?.data?.name}/products`,{state:b?.products})} alt={b?.image} src={b?.image} className={`w-[100%] md:h-[100vh] ${b?.full_screen ? 'md:object-stretch' :  'md:object-contain md:-ml-5'} `} />
          
          {b?.full_screen == false &&
          <div className={`mt-5 md:ml-10`}>
            <h6 className='text-[14.5px] md:w-[90%] font_thick mb-5'> {b?.summary}</h6>
            <h6 onClick={()=>navigator(`/products_category/${state?.data?.name}/products`,{state:b?.products})} className='w-[110px] text-center transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer bg-black text-white hover:scale-105 font-[300] font_thick text-[12px]'>View Collection</h6>
          </div>}
          </div>
          ))}

    </div>
  )
}

export default Productscategory