import React from 'react'

import logo1 from '../images/logo_set/logo1.png'
import logo2 from '../images/logo_set/logo2.png'
import logo3 from '../images/logo_set/logo3.png'
import logo4 from '../images/logo_set/logo4.jpg'

function Introduction() {
  return (
    <div className='w-screen overflow-x-hidden z-10'>
         <div className='relative w-full px-10 sm:px-0 mx-auto md:w-8/12 py-7 my-0'>

         <h6 data-aos="fade-left" className='text-[50px] md:text-[70px] -ml-2 text-[#8ae324] font_thicker'>Introduction</h6> 
         <h6 className='text-[14px]  mb-5 font-[400] text-justify font_thick'>Diwani Group started its first entity in the year 1991 to supply cut-size wood with
          an innate focus on consistently supreme quality, an ethical and transparent
          business process, and a firm commitment to deadlines. This helped the company gain adequate attention and precious faith. Since its inception, the Diwani Group has been steadily growing, widening and diversifying. WudGres was born out of this diversification, and 2 manufacturing units spread over 2,00,000 square feet cater to the demand. With the successful acceptance of products in the market and an expanding customer base, a third unit covering 45000 square feet is planned to meet the ever-increasing demand.</h6>
          <h6 className='text-[18px]  mb-10 mt-10 font-[800] text-justify  font_bold'>Brand Owned By Diwani Group</h6>

          <div className='grid gap-4 mt-5 mb-10 md:grid-cols-5'>
            <img src={logo4} className=' md:w-[80%] mb-4' />
            <img src={logo3} className=' md:w-[80%] mb-4' />
            <img src={logo1} className=' md:w-[80%] mb-4' />
            <img src={logo2}  className=' md:w-[80%] mb-4'/>
           
          </div>

          </div>

      </div>
  )
}

export default Introduction