import React from 'react'
import Logo from '../images/logo_set/logo4.jpg'

function Splash() {
  return (
    <div className='min-h-screen max-h-screen min-w-screen max-w-screen overflow-x-hidden grid place-items-center'>
      <div>
        <img data-aos="fade-zoom-in" 
             data-aos-easing="ease-in-back"
             data-aos-delay="300"
             data-aos-offset="0"
             alt="_brand" 
             src={Logo}
             className='w-[150px] opacity-75 md:w-96' />
        </div>
    </div>
  )
}

export default Splash