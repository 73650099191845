import React from 'react'

function Warrenty() {
  return (
    <div className='w-9/12 mx-auto my-20'>
        <h6 className='text-[15px] my-5 font-[500] font-bold'>WARRANTIES</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 font_thick'>WudGres warrants that each door bearing the WudGres brand and identification mark complies with Industry Standard and all revisions in effect as of the date of manufacture, and WudGres further warrants, except as stated below, that each such door, at the time of the shipment from WudGres, is of good material and workmanship and free from defects that would render such door unserviceable or unfit for the ordinary, recommended use. The WudGres limited warranty applies to new doors other than those sold "as is". Warranty periods for specific doors, and exclusions,follow:</h6>
        <h6 className='text-[15px] my-5 font-[500] font-bold'>THREE YEAR DOOR WARRANTY</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 font_thick'>This warranty shall survive for Three year after the date of shipment from WudGres.This warranty applies to doors properly sealed, finished and, in the case of exterior applications, hung in a location protected from exposure to direct sun or rain under normal conditions. See specific Fitting and Hanging instructions.</h6>
        <h6 className='text-[15px] my-5 font-[500] font-bold'>CHARACTERISTICS EXCLUDED FROM THESE WARRANTIES</h6>  
        <h6 className='text-[13px] mb-5 opacity-90 font_thick'>The following are not defects in workmanship or material and are not covered by these written warranties:</h6>

        <h6 className='text-[13px] pb-2 leading-[25px] opacity-90 font_thick'>1. Damage caused by attempts other than WudGres Technical person to repair the door.</h6>
        <h6 className='text-[13px] pb-2 leading-[25px] opacity-90 font_thick'>2. Failure to properly seal the door. All edges must be sealed within 72 hours after fitting and hanging with the integrity of the finish maintained.</h6>
        <h6 className='text-[13px] pb-2 leading-[25px] opacity-90 font_thick'>3. Natural variations in the color or texture of the door.</h6>
        <h6 className='text-[13px] pb-2 leading-[25px] opacity-90 font_thick'>4. Doors that are not finished and hung in accordance with the Care and Finishing instructions; and doors/components that are exposed to direct sun or rain under normal conditions.</h6>
        <h6 className='text-[13px] pb-2 leading-[25px] opacity-90 font_thick'>5a. For doors 3' x 7' or smaller; warp not exceeding I /4" in the plane of the door itself. For doors larger than 3' x 7'5 warranty does not apply. Warranty can apply for I -3/4" doors up to 3'6" x 8'0" if properly installed with a three-point locking mechanism.</h6>
        <h6 className='text-[13px] pb-2 leading-[25px] opacity-90 font_thick'>5b. Addressing claims due to warp may be deferred for up to 12 months from the date of the claim in order to allow the door to acclimate to local humidity and temperature conditions. This deferral will not be counted against the warranty period.</h6>
        <h6 className='text-[13px] pb-2 leading-[25px] opacity-90 font_thick'>6. Defects caused by ( I ) warp or bow of the frame or jamb in which the door is hung or (2) improper installation such that the door does not swing freely.</h6>
        <h6 className='text-[13px] pb-2 leading-[25px] opacity-90 font_thick'>7. Damage caused by improper handling or on-site storage. Storage damage includes moisture in doors due to humidity.</h6> 
        <h6 className='text-[13px] pb-2 leading-[25px] opacity-90 font_thick'>8. Panel misalignment or shrinkage is not considered a defect</h6>
        <h6 className='text-[13px] pb-2 leading-[25px] opacity-90 font_thick'>9. The performance or appearance of finishes applied is not covered.</h6>
        <h6 className='text-[13px] pb-2 leading-[25px] opacity-90 font_thick'>10. Expansion of bottom rail due to climate conditions is not considered a defect.</h6>
        <h6 className='text-[13px] pb-2 leading-[25px] opacity-90 font_thick'>11. Damage caused by extreme temperature build-up where a storm door or dark paint or stain is utilized.</h6>
        <h6 className='text-[13px] pb-2 leading-[25px] opacity-90 font_thick'>12. Failure to perform normal homeowner's maintenance, including maintaining the finish.</h6>
        <h6 className='text-[13px] pb-2 leading-[25px] opacity-90 font_thick'>13. Surface checking of hardwoods is considered a natural characteristic and is not covered by this warranty.</h6>
        <h6 className='text-[13px] pb-2 leading-[25px] opacity-90 font_thick'>14. Defects caused by inadequate overhang (see WudGres Care and Finishing instructions for adequate overhang).</h6>
        <h6 className='text-[13px] pb-2 leading-[25px] opacity-90 font_thick'>15. Damage or defects caused by house settling or acts of God.</h6>


        <h6 className='text-[15px] my-5 font-[500] font-bold'>HOW TO MAKE A CLAIM</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 font_thick'>Immediately upon discovering that the door is defective or fails to conform to this written warranty,the end-user shall call and inform the company or by e-mailing the complaint to wudgres@gmail.com by writing"Complaint" in the Subject Line.</h6>

        <h6 className='text-[15px] my-5 font-[500] font-bold'>SPECIAL NOTES</h6>
        <h6 className='text-[13px] leading-[25px] opacity-90 font_thick'>WudGres shall not be liable for consequential, indirect or incidental damages, or for any amount in excess of the purchase price of the defective door, whether the claim is for breach of warranty or negligence. In Case if the door cannot be repaired, it will be Replaced and WudGres will not be responsible for the installation or finishing of replacement door or door components.All the disputes are Subject to Bengaluru Jurisdiction.</h6>
    </div>
  )
}

export default Warrenty